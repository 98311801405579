import {createContext, FC, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {CognitoUser} from "amazon-cognito-identity-js";
import StripeSignup from "./StripeSignup";
import {SignOut} from "@aws-amplify/ui-react/dist/types/components/Authenticator/Authenticator";
import {Text, Button, Divider, Heading, View, Loader} from "@aws-amplify/ui-react";
import {Auth} from "@aws-amplify/auth";

interface CognitoAttributes {
    email: string;
    phone_number: string;
    given_name?: string
    family_name?: string
    website?: string
    "custom:City"?: string
    "custom:Company_Address"?: string
    "custom:Company_Name"?: string
    "custom:Country"?: string
    "custom:Postal_Code"?: string
    "custom:environment"?: string
}

export interface AmplifyUser extends CognitoUser {
    username?: string;
    attributes?: CognitoAttributes;
}


interface MerchantContext {
    merchant?: Merchant
}

const merchantContext = createContext({} as MerchantContext);


export interface Merchant {
    "name": string
    "email": string
    "brands": [string],
    "stripe_details_submitted": boolean,
    "validated": boolean,
    "business_type": string,
    "company": {
        "name": string,
        "address": {
            "city": string,
            "country": string,
            "line1": string,
            "line2": string,
            "postal_code": string
        }
    },
    "country": string,
    "stripe_id": string,
    "stripe_link": {
        object: string,
        created: number,
        expires_at: number,
        url: string

    }
    "url": string
}

export const useMerchant = () => {
    return useContext(merchantContext);
}

function useProvideMerchant(props: ProvideMerchantProps, setLoading: ((p: boolean) => void)): MerchantContext {
    const [merchant, setMerchant] = useState<Merchant>()
    const hasFetchedData = useRef(false);
    useEffect(() => {
        async function fetchMerchant() {
            const attributes = props.user?.attributes;
            fetch(process.env.REACT_APP_STRIPE_URL + "/merchant/connect", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "env": process.env.REACT_APP_STRIPE_ENV || "",
                    "email": attributes?.email || "",
                    "company_name": attributes?.["custom:Company_Name"] || "",
                    "company_url": attributes?.["website"] || "",
                    "city": attributes?.["custom:City"] || "",
                    "address_line1": attributes?.["custom:Company_Address"] || "",
                    "address_line2": "",
                    "country": attributes?.["custom:Country"] || "",
                    "postal_code": attributes?.["custom:Postal_Code"] || "",
                    "first_name": attributes?.["given_name"],
                    "last_name": attributes?.["family_name"],
                    "validated": false,
                    "phone_number": attributes?.["phone_number"] // this is not used at the moment
                })
            }).then(response => {
                if (response.status !== 200) {
                    props.signOut?.();
                    throw Error();
                }
                return response.json();
            }).then(merchant_ => {
                setMerchant(merchant_);
            }).finally(() => setLoading(false));
        }

        if (!hasFetchedData.current) {
            fetchMerchant();
            hasFetchedData.current = true;
        }
    }, [props, setLoading]);
    return {merchant: merchant};
}

interface ProvideMerchantProps {
    user?: AmplifyUser
    signOut?: SignOut
    children: ReactNode | ReactNode[]
}

const NotValidateComponent = ({currentUser, props}: { currentUser: AmplifyUser | undefined, props: any }) => (
    <View as={"section"} className={"continue-on-stripe"}>
        <View>
            <Heading level={3}>
                Hi {currentUser?.attributes?.given_name}, your account needs to be validated by our
                team.<br/> Please wait for a confirmation email.
            </Heading>


            <Button maxWidth={"300px"} variation={"primary"} loadingText="" ariaLabel="Sign out"
                    onClick={props.signOut}>
                Sign out
            </Button>
            <Divider orientation={"horizontal"} margin={"20px 0"}/>
            <Text>For any problem contact us at <a
                href={"mailto:info@neatandshaken.com"}>info@neatandshaken.com</a>
            </Text>
        </View>
    </View>
);

const FulfillStripeComponent = ({merchant, props}: { merchant: Merchant | undefined, props: any }) => (
    <StripeSignup isLoading={merchant === undefined} user={props.user} merchant={merchant}/>
);

const ProvideMerchant: FC<ProvideMerchantProps> = (props) => {
    const [loading, setLoading] = useState(true);
    const context: MerchantContext = useProvideMerchant(props, setLoading);
    const [currentUser, setCurrentUser] = useState(props.user);

    useEffect(() => {
        Auth.currentAuthenticatedUser({bypassCache: true}).then(user => {
            setCurrentUser(user);
        });
    }, [])
    let merchant = context.merchant;
    const is_validated = merchant?.validated && props.user?.attributes?.["custom:environment"] && merchant.brands.length > 0;
    if (loading) {
        return <Loader/>
    } else if (!merchant?.stripe_details_submitted) {
        return <FulfillStripeComponent merchant={context?.merchant} props={props}/>
    } else if (!is_validated) {
        return <NotValidateComponent currentUser={currentUser} props={props}/>
    } else {
        return <merchantContext.Provider value={context}>{props.children}</merchantContext.Provider>;
    }
}
export default ProvideMerchant;
