import {
    Button,
    CheckboxField,
    Image,
    SelectField,
    Text,
    Theme,
    useAuthenticator,
    useTheme,
    View
} from "@aws-amplify/ui-react";
import {Authenticator} from '@aws-amplify/ui-react';

export const nsTheme: Theme = {
    name: 'ns-theme',
    tokens: {
        components: {
            button: {
                primary: {
                    backgroundColor: {
                        value: '#D13F33'
                    },
                }
            }
        },
    },
};

export const authenticatorComponents = {
    Header() {
        const {tokens} = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image
                    alt="Neat & Shaken Pro"
                    src="/logo-big.png"
                />
            </View>
        );
    },

    Footer() {
        const {tokens} = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; Neat & Shaken 2022
                </Text>
            </View>
        );
    },

    SignIn: {
        Header() {
            return (
                <div/>
            );
        },
        Footer() {
            const {toResetPassword} = useAuthenticator();

            return (
                <View textAlign="center">
                    <Button fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
                        Forgot your password?
                    </Button>
                </View>
            );
        },
    },

    SignUp: {
        Header() {
            return (
                <div/>
            );
        },
        Footer() {
            const {toSignIn} = useAuthenticator();
            return (
                <View textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toSignIn}
                        size="small"
                        variation="link"
                    >
                        Back to Sign In
                    </Button>
                </View>
            );
        },
        FormFields() {
            const {validationErrors} = useAuthenticator();

            return (
                <>
                    <Authenticator.SignUp.FormFields/>

                    <SelectField label="Country" descriptiveText="Insert the country where your company is located"
                                 isRequired={true}
                                 errorMessage={validationErrors.acknowledgement as string}

                                 labelHidden={true}
                                 placeholder={"Select your country..."}
                                 name={"custom:Country"}
                                 hasError={!!validationErrors.acknowledgement}
                    >
                        <option value="FR">France</option>
                        <option value="GB">United Kingdom</option>
                    </SelectField>
                    <CheckboxField
                        isRequired={true}
                        errorMessage={validationErrors.acknowledgement as string}
                        hasError={!!validationErrors.acknowledgement}
                        name="acknowledgement"
                        value="yes"
                        label="I agree with the Terms & Conditions"
                    />
                </>)
        }
    },
    ConfirmSignUp: {
        Header() {
            return (
                <div/>
            );
        },
        Footer() {
            return <Text>For any problem contact <a
                href="mailto:info@neatandshaken.com">info@neatandshaken.com</a></Text>;
        },
    },
    SetupTOTP: {
        Header() {
            return (
                <div/>
            );

        },
        Footer() {
            return <Text>For any problem contact <a
                href="mailto:info@neatandshaken.com">info@neatandshaken.com</a></Text>;
        },
    },
    ConfirmSignIn: {
        Header() {
            return (
                <div/>
            );
        },
        Footer() {
            return <Text>For any problem contact <a
                href="mailto:info@neatandshaken.com">info@neatandshaken.com</a></Text>;
        },
    },
    ResetPassword: {
        Header() {
            return (
                <div/>
            );
        },
        Footer() {
            return <Text>For any problem contact <a
                href="mailto:info@neatandshaken.com">info@neatandshaken.com</a></Text>;
        },
    },
    ConfirmResetPassword: {
        Header() {
            return (
                <div/>
            );
        },
        Footer() {
            return <Text>For any problem contact <a
                href="mailto:info@neatandshaken.com">info@neatandshaken.com</a></Text>;
        },
    },
};

export const authenticatorFormFields = {
    signIn: {
        username: {
            labelHidden: true,
            placeholder: 'Email',
        },
    },
    signUp: {
        password: {
            label: 'Password:',
            placeholder: 'Password',
            isRequired: true,
        },
        phone_number: {
            dialCode: '+44'
        },
        given_name: {
            placeholder: 'First Name'

        },
        family_name: {
            placeholder: 'Last Name'
        },
        "custom:Company_Name": {
            placeholder: 'Company Name',
            label: 'Company Name',
            labelHidden: true,
            isRequired: true,
        },
        "Company_Address": {
            placeholder: 'Company Address',
            label: 'Company Address',
            labelHidden: true,
            isRequired: true,
        },
        "address": {
            placeholder: 'address',
            label: 'address',
            labelHidden: true,
            isRequired: true,
            type: "hidden",
            value: "address"
        }
    },
    forceNewPassword: {
        password: {
            labelHidden: false,
            placeholder: 'Enter your Password:',
        },
    },
    resetPassword: {
        username: {
            labelHidden: false,
            placeholder: 'Enter your email:',
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            labelHidden: false,
            placeholder: 'Enter your Confirmation Code:',
            label: 'Enter the confirmation Code:',
            isRequired: false,
        },
        confirm_password: {
            labelHidden: false,
            placeholder: 'Enter your Password Please:',
        },
    },
    setupTOTP: {
        QR: {
            totpIssuer: 'test issuer',
            totpUsername: 'amplify_qr_test_user',
        },
        confirmation_code: {
            labelHidden: false,
            label: 'Enter your confirmation code received by SMS',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
        },
    },
    confirmSignIn: {
        confirmation_code: {
            labelHidden: false,
            label: 'Enter your confirmation code received by SMS',
            placeholder: 'Enter your Confirmation Code:',
            isRequired: false,
        },
    },
};

