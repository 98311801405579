import {HttpError} from "react-admin";
import {Auth} from "@aws-amplify/auth";
import {CognitoUser} from "amazon-cognito-identity-js";

const authProvider = {
    login(input: { username: string, password: string }) {
        return Promise.reject();
    },
    logout: () => {
        return Auth.signOut();
    },
    checkAuth: () => {
        return Auth.currentUserInfo();
    },
    checkError: (error: HttpError) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            return Auth.signOut();
        }
        return Promise.resolve();
    },
    getIdentity: () => {
        return Auth.currentAuthenticatedUser().then((user: CognitoUser | any) => {
            return {
                id: user.getUsername(),
                fullName: user.attributes.first_name
            }
        })

    },
    getPermissions: () => {
        return Auth.currentAuthenticatedUser({bypassCache: true}).then((user: CognitoUser | any) => {
            return user.attributes["custom:groups"].split(",");
        }).catch((e) => {
            return [];
        });
    },
};


export default authProvider;
