import {Card, Chip, Stack, Typography} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import AcceptIcon from '@mui/icons-material/Grading';
import {
    ArrayField,
    BooleanField,
    Button,
    ChipField,
    Datagrid,
    DateField,
    EmailField,
    FunctionField,
    ImageField,
    List,
    NumberField,
    ReferenceField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TextInput,
    UrlField,
    useListContext,
    useRecordContext,
    useTranslate
} from 'react-admin';
import {useCallback, useEffect, useRef, useState} from "react";

const DownloadInvoiceField = ({source, label}: any) => {
    const record = useRecordContext();
    const value = record[source];
    if (value) {
        return <FunctionField label={label}
                              render={(record: any) => <Button startIcon={<DownloadIcon/>} label="View"/>}/>
    } else {
        return <></>
    }
}


const AcceptInvoiceField = ({source, label}: any) => {
    const record = useRecordContext();
    const [isGenerating, setIsGenerating] = useState(false)
    const isMounted = useRef(true)

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, []);

    const value = record[source];
    const generateInvoice = useCallback(async () => {
        if (isGenerating) {
            return;
        }
        setIsGenerating(true);
        // CALL
        if (isMounted.current) {
            setIsGenerating(false);
        }

    }, [isGenerating]);

    if (value) {
        return <FunctionField label={label}
                              render={(record: any) => <Button onClick={generateInvoice} startIcon={<AcceptIcon/>}
                                                               label="Approve"/>}/>
    } else {
        return <></>
    }
}

const CurrencyCentsFields = ({source, label}: any) => {
    const record = useRecordContext();
    const value = record[source] / 100;
    return (<NumberField label={label} locales={"en-GB"} options={{"style": "currency", "currency": "GBP"}}
                         source="value"
                         record={{"value": value}}/>)
};

const ProdStatusChip = ({source}: any) => {
    const record = useRecordContext();
    const color = record[source] == "ACTIVE" ? "success" : "warning";
    return (<ChipField source={source} label="Status" color={color}/>);
}

const PayoutStatusChip = ({source}: any) => {
    const record = useRecordContext();
    const color = record[source] == "Paid" ? "success" : ("Pending" ? "warning" : "error");
    return (<ChipField source={source} label="Status" color={color}/>);


}
const FullNameField = ({first, last, label}: any) => {
    const record = useRecordContext();
    const firstName = record[first];
    const lastName = record[last];
    return (<TextField label={label} source="value" record={{"value": firstName + " " + lastName}}/>)
}

const BrandsField = ({source}: any) => {
    const record = useRecordContext();
    const brands = record[source].map((brand: string) => ({"name": brand.toUpperCase()}));
    return (<ArrayField source="value" record={{"value": brands}}>
        <SingleFieldList>
            <ChipField source="name"/>
        </SingleFieldList>
    </ArrayField>);
}

function formatMoney(cents: number) {
    var formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
    });
    return formatter.format(cents / 100);
}

const OrdersAside = () => {
    const {data, isLoading} = useListContext();
    if (isLoading) return null;
    return (
        <div style={{width: 200, margin: '1em'}}>
            <Typography variant="h6">Orders stats</Typography>
            <Typography variant="body2">
                Total revenue: {formatMoney(data.reduce((sum, order) => sum + order.revenue, 0))}
            </Typography>
            <Typography variant="body2">
                Products sold: {data.reduce((sum, order) => sum + order.quantity, 0)}
            </Typography>
        </div>
    );
};
export const OrderList = () => (
    <List aside={<OrdersAside/>}>
        <Datagrid>
            <TextField source="order_name" label={"Name"}/>
            <DateField source="order_datetime" label={"Order Creation"}/>
            <NumberField source="quantity"/>
            <CurrencyCentsFields source="revenue"/>
            <ReferenceField source="product.id" link="show" reference="products" label={"Product"}>
                <Card>
                    <Stack direction={"row"}>
                        <ImageField source="variant_thumb" label=" "
                                    sx={{'& img': {maxWidth: 60, maxHeight: 60, objectFit: 'contain'}}}/>
                        <TextField source={"variant_name"} style={{paddingTop: "25px"}}/>
                    </Stack>
                </Card>
            </ReferenceField>
        </Datagrid>
    </List>
);

const QuickFilter = ({label}: any) => {
    const translate = useTranslate();
    return <Chip sx={{marginBottom: 1}} label={translate(label)}/>;
};
const productFilters = [
    <TextInput name="name" label="Search" source="name" alwaysOn/>,
    <QuickFilter source="status" label="Only Active" defaultValue="ACTIVE"/>
];

export const ProductShow = () => (
    <Show>
        <SimpleShowLayout>
            <ImageField source="variant_thumb" label=" "/>
            <TextField source="sku" label="SKU"/>
            <TextField source="variant_name" label="Name"/>
            <NumberField source="stock" label="Stock"/>
            <CurrencyCentsFields source="price" label="Price"/>
            <UrlField source="url"/>
        </SimpleShowLayout>
    </Show>

)

export const ProductList = () => (
    <List filters={productFilters}>
        <Datagrid bulkActionButtons={false} rowClick={"show"}>
            <TextField source="sku" label="SKU"/>
            <ImageField source="variant_thumb" label=" "/>
            <TextField source="variant_name" label="Name"/>
            <NumberField source="stock" label="Stock"/>
            <CurrencyCentsFields source="price" label="Price"/>
            <ProdStatusChip source="status"/>

        </Datagrid>
    </List>

);


export const UserList = () => (
    <List>
        <Datagrid rowClick="edit">
            <TextField source="company.name" label="Company"/>
            <BrandsField source="brands"/>
            <UrlField source="url"/>
            <FullNameField label="Full Name" first="first_name" last="last_name"/>
            <EmailField source="email"/>
            <TextField source="country"/>
            <TextField source="phone_number"/>
            <BooleanField source="stripe_details_submitted" label="Registered on Stripe"/>
            <BooleanField source="validated"/>
        </Datagrid>
    </List>
);


export const PayoutList = () => (

    <List>
        <Datagrid>
            <TextField source="month" label="Period"/>
            <PayoutStatusChip source="status"/>
            <CurrencyCentsFields source="amount" label="Amount"/>
            <DownloadInvoiceField label="&nbsp;" source="can_download_invoice"/>
            <AcceptInvoiceField label="&nbsp;" source="can_approve"/>
        </Datagrid>
    </List>


);
