import {
    Edit,
    SimpleForm,
    SelectArrayInput,
    TextInput,
    BooleanInput, useRecordContext,
} from 'react-admin';
import {Typography} from '@mui/material';
import Box from "@mui/material/Box";

const BrandsInput = () => {
    const record = useRecordContext();
    const brands = record["brands"].map((brand: string) => ({id: brand, "name": brand.toUpperCase()}));
    return (<SelectArrayInput
        onCreate={() => {
            const newBrandName = prompt('Enter a new brand');
            if (newBrandName) {
                const newBrand = {id: newBrandName.toLowerCase(), name: newBrandName.toUpperCase()};
                brands.push(newBrand);
                return newBrand;
            }
        }}
        source="brands"
        choices={brands}
    />);

}

export const UserEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <Typography variant="h6" gutterBottom>
                    Identity
                </Typography>
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <Box flex={0.5} mr={{xs: 0, sm: '0.5em'}}>
                        <TextInput disabled source="first_name" label="First Name" name="first_name" fullWidth/>
                    </Box>
                    <Box flex={0.5} ml={{xs: 0, sm: '0.5em'}}>
                        <TextInput disabled source="last_name" label="Last Name" name="last_name" fullWidth/>
                    </Box>
                    <Box flex={1} ml={{xs: 0, sm: '0.5em'}}>
                        <TextInput disabled source="email" name="email" fullWidth/>
                    </Box>
                </Box>
                <Typography variant="h6" gutterBottom>
                    Brands
                </Typography>
                <Box display={{xs: 'block', sm: 'flex'}}>
                    <BrandsInput/>
                </Box>
                <BooleanInput name="validated" label="Validated" source="validated" options={{}}/>
            </SimpleForm>
        </Edit>
    );
}