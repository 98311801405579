import red from '@mui/material/colors/red';
import {defaultTheme} from 'react-admin';

const myTheme = {
    ...defaultTheme,
    palette: {
        secondary: {
            main: "#fff3f3"
        },
        primary: {
            main: "#D13F33"
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },

};

export default myTheme;