import React, {RefObject, useCallback, useEffect, useRef, useState} from 'react';
import "./InvoiceStyle.css"
import {useSearchParams} from "react-router-dom";
import httpClient, {fetchAuthenticated} from "../providers/HttpClient";
import {Button} from "@aws-amplify/ui-react";


const centFormatter = (cent: number) => (
    new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(cent / 100)
)


interface Company {
    name: string
    address: {
        city: string,
        country: string,
        line1: string,
        line2: string,
        postal_code: string

    }
}

const countries: { [key: string]: string } = {
    "FR": "France",
    "UK": "United Kingdom",
    "": ""
}

const Approve = ({invoiceText}: { invoiceText: RefObject<HTMLDivElement> }) => {
    const useDownload = useCallback(() => {
        fetchAuthenticated(process.env.REACT_APP_API_URL + "/invoice/generate", {
            method: "POST",
            body: invoiceText.current?.innerHTML
        })
            .then((response) => {
                return response.blob();
            })
            .then(blob => {
                const file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            });
    }, []);

    return <Button onClick={useDownload} variation="primary">Approve</Button>
}


const Header = ({
                    start,
                    end,
                    company,
                    invoiceId
                }: { start: string, end: string, company: Company | undefined, invoiceId: string }) => (
    <div id="header">
        <div id="header_bar"/>
        <div id="header_1">
            <div id="first_row">
                <h1>
                    {company?.name}
                </h1>
                <div id="datetime">
                    <div id="invoice_title">Invoice</div>
                    <div id="date">
                        {new Date(Date.now()).toLocaleString().split(',')[0]}
                    </div>
                    <div id="invoice_id">
                        {invoiceId}
                    </div>
                </div>
            </div>
            <div id="company">
                <h2 id="address">
                    {company?.address.line1}<br/>
                    {company?.address.line2}<br/>
                    {company?.address.city}<br/>
                    {countries[company?.address.country || ""]}
                </h2>
            </div>
        </div>
        <h2 id="header_2">
            Orders from {start} to {end}
        </h2>
    </div>

);
const BillTo = ({}) => (
    <div id="bill_to">
        <div id="bill_to_header">Bill to:</div>
        <div>Neat & Shaken</div>
        <div>Warrington</div>
        <div>Cheshire, WA3 6PH</div>
        <div>United Kingdom</div>

    </div>
)
const Table = (
    {
        orders
    }
        :
        {
            orders: any[]
        }
) => {
    const Order = (order: any) => {
        const dateFormatted = new Date(order.order_datetime).toLocaleString().split(",")[0];
        return <tr key={order.id}>
            <td>{dateFormatted}</td>
            <td className="number-cell">{order.quantity}</td>
            <td>{order.product.variant_name}</td>
            <td className="number-cell">{centFormatter(order.revenue)}</td>
        </tr>
    };

    return <table id="table">
        <thead>
        <tr>
            <th>Date</th>
            <th>Quantity</th>
            <th>Item</th>
            <th>Price</th>
        </tr>
        </thead>
        <tbody>
        {orders.map(Order)}
        </tbody>
    </table>
}
const Total = (
    {
        orders
    }
        :
        {
            orders: any[]
        }
) => {
    if (orders.length > 0) {

        const total = orders.reduce((sum, order) => sum + order.revenue, 0);
        return <div id="total">
            <div>Total due:<span id="amount">{centFormatter(total)}</span></div>

        </div>
    } else {
        return <div/>
    }
}

export const Invoice = () => {
        const [searchParams, setSearchParams] = useSearchParams();
        const invoiceText = useRef<HTMLDivElement>(null);
        const [invoiceId, setInvoiceId] = useState("");
        const [orders, setOrders] = useState([]);
        const [company, setCompany] = useState<Company | undefined>(undefined);
        const month = parseInt(searchParams.get("month") || "1"); // like 1 for January etc
        const year = parseInt(searchParams.get("year") || "" + (new Date()).getFullYear());
        const start = new Date(year, month - 1, 1).toLocaleString().split(",")[0];
        const end = new Date(year, month, 0).toLocaleString().split(",")[0];
        const hasFetchedData = useRef(false);

        useEffect(() => {

                document.body.classList.add('invoice');

                async function fetchOrders() {
                    httpClient(process.env.REACT_APP_API_URL + "/invoice/2022/10", {})
                        .then(({json}) => {
                            setInvoiceId(json["id"]);
                            setOrders(json["orders"]);
                            setCompany(json["user"]["company"])
                        });
                }

                if (!hasFetchedData.current) {
                    fetchOrders();
                    hasFetchedData.current = true;
                }
            }
            , [])
        return <div id="container">
            <div id="page" ref={invoiceText}>
                <div id="invoice">
                    <Header invoiceId={invoiceId} start={start} end={end} company={company}/>
                    <BillTo/>
                    <div id="center">
                        <Table orders={orders}/>
                        <Total orders={orders}/>
                    </div>
                </div>
            </div>
            <div id="actions">
                <Approve invoiceText={invoiceText}/>
            </div>
        </div>
    }
;