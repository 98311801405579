import {UserMenu, AppBar, AppBarProps, Layout, LayoutProps} from 'react-admin';
import {Auth} from "@aws-amplify/auth";
import Box from "@mui/material/Box"
import {FC, forwardRef, useEffect, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {View, Text} from "@aws-amplify/ui-react";

const Logo = () => {
    return <Box
        component="img"
        sx={{display: {xs: 'none', md: 'flex'}, mr: 1, height: "45px"}}
        alt="Logo"
        src="/logo-square.png"
    />;
}

const MyLogoutButton = forwardRef((props: any, ref) => {
    const handleClick = () => Auth.signOut();
    return (
        <MenuItem ref={ref} onClick={handleClick} {...props}>
            <ListItemIcon>
                <ExitIcon/>
            </ListItemIcon>
            <ListItemText>
                Logout
            </ListItemText>
        </MenuItem>
    );
});

const InitialIcon: FC<{ initials: string }> = ({initials}) => {
    return (
        <View
            style={{
                backgroundColor: '#438667',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 15,
                width: 30,
                height: 30,
            }}>
            <Text style={{
                color: 'white',
                marginLeft: "1px",
                marginTop: "3px",
                letterSpacing: "1px",
                fontSize: 16,
                fontFamily: "Verdana"
            }}>{initials}</Text>
        </View>
    );
};

const MyUserMenu = () => {
    const [initials, setInitials] = useState("");
    useEffect(() => {
        Auth.currentAuthenticatedUser().then(user => {
            setInitials(user.attributes.given_name[0] + user.attributes.family_name[0]);
        });
    }, []);
    return <UserMenu icon={<InitialIcon initials={initials}/>}><MyLogoutButton/></UserMenu>;
}


const MyAppBar = (props: AppBarProps) => (
    <AppBar{...props} userMenu={<MyUserMenu/>}>
        <Logo/>
        <Box sx={{flexGrow: 1}}/>
    </AppBar>
);

const MyLayout = (props: LayoutProps) => {
    return <Layout {...props} appBar={MyAppBar}/>
};

export default MyLayout;
