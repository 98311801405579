import {fetchUtils, Options} from "react-admin";
import {Auth} from "@aws-amplify/auth";

let httpClient: (url: any, options?: Options) => Promise<{ status: number; headers: Headers; body: string; json: any }>;
let fetchAuthenticated: (url: any, options?: Options) => Promise<Response>;


httpClient = async function (url, options = {}) {
    var urlO = new URL(url);
    return Auth.currentSession().then(res => {
        let accessToken = res.getAccessToken()
        return accessToken.getJwtToken();
    }).then(jwt => {
        options.user = {
            authenticated: true,
            token: jwt
        };
        return fetchUtils.fetchJson(urlO.href, options);
    });
}

fetchAuthenticated = async function (url, options = {user: undefined}) {
    var urlO = new URL(url);
    return Auth.currentSession().then(res => {
        let accessToken = res.getAccessToken()
        return accessToken.getJwtToken();
    }).then(jwt => {
        options.user = {
            authenticated: true,
            token: jwt
        };
        return fetch(urlO, options);
    });
}
export {fetchAuthenticated};

export default httpClient;