import {AmplifyUser, Merchant} from "./MerchantProvider";
import {Button, Heading, Image, Link, View} from "@aws-amplify/ui-react";
import {useEffect} from "react";

interface StripeSignupProps {
    user: AmplifyUser | undefined
    isLoading: boolean
    merchant: Merchant | undefined
}

const StripeSignup = (props: StripeSignupProps) => {
    useEffect(() => { //there is some issue with the state.......
        const timer = setTimeout(() => {
            if (!props.merchant?.stripe_link.url) {
                window.location.reload();
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [props]);
    return (
        <View as={"section"} className={"continue-on-stripe"}>
            <View>
                <Heading level={3}>
                    Neat & Shaken is <Image width={"200px"} alt={"powered by stripe"}
                                            src={"/stripe-logo.svg"}></Image>
                </Heading>
                <Link href={props.merchant?.stripe_link.url}>
                    <Button isLoading={props.isLoading} variation={"primary"} loadingText=""
                            ariaLabel="Continue to stripe">
                        Continue your registration on Stripe
                    </Button>
                </Link>
            </View>
        </View>
    )
}

export default StripeSignup;