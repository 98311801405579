import * as React from "react";
import simpleRestProvider from 'ra-data-simple-rest';
import OrderIcon from '@mui/icons-material/Receipt';
import ProductIcon from '@mui/icons-material/Liquor';
import PayoutIcon from '@mui/icons-material/Payments';
import UserIcon from '@mui/icons-material/Group';
import { Route } from "react-router-dom";
import {Admin, CustomRoutes, Resource} from 'react-admin';
import myTheme from "./components/MyTheme";
import myLayout from "./components/MyLayout"
import {Amplify} from "aws-amplify";
import awsconfig from "./aws-exports";
import {Authenticator, ThemeProvider} from '@aws-amplify/ui-react';
import httpClient from "./providers/HttpClient";
import {authenticatorComponents, authenticatorFormFields, nsTheme} from "./components/Amplify";
import authProvider from "./components/Authenticator";
import ProvideMerchant from "./components/MerchantProvider";
import {OrderList, PayoutList, ProductList, ProductShow, UserList} from "./components/Lists";
import {UserEdit} from "./components/Edits";
import {Invoice} from "./components/Invoice";

Amplify.configure(awsconfig);


const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL || "", httpClient);


export default function App() {
    return (
        <ThemeProvider theme={nsTheme}>
            <Authenticator components={authenticatorComponents} formFields={authenticatorFormFields}>
                {({signOut, user}) => {
                    return (
                        <ProvideMerchant signOut={signOut} user={user}>
                            <Admin layout={myLayout} theme={myTheme} dataProvider={dataProvider}
                                   authProvider={authProvider}>{(permissions: [string]) => (<>
                                <Resource icon={OrderIcon} name="orders" list={OrderList}/>
                                <Resource icon={ProductIcon} name="products" list={ProductList} show={ProductShow}/>
                                <Resource icon={PayoutIcon} name="payouts" list={PayoutList}/>
                                <CustomRoutes noLayout>
                                    <Route path="/invoice" element={<Invoice/>}/>
                                </CustomRoutes>
                                {permissions.includes("admin") ?
                                    <><Resource icon={UserIcon} name="users" list={UserList} edit={UserEdit}/></>
                                    : <></>
                                }</>)}
                            </Admin>
                        </ProvideMerchant>);
                }}
            </Authenticator>
        </ThemeProvider>
    )
}
